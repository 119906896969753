import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import logo from "../../assets/images/KoffieVanKai_primary_logo_RGB_eggshell.svg";

const ComingSoonView = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    fade: false,
    speed: 1800,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };
  return (
    <div className="comingsoon">
      <div className="comingsoon__text">
        <div className="comingsoon__text--logo">
          <img src={logo} alt="Koffie van Kai" />
        </div>
        <div className="comingsoon__text--slider">
          <p>
            Hier komt de nieuwe website van Koffie van Kai.
            <br /> Nog héél even geduld, want...
          </p>
          <Slider {...settings}>
            <h1>Ik ben koffie zetten</h1>
            <h1>De koffie wordt gebrand</h1>
            <h1>De koffie is onderweg</h1>
            <h1>Even de melk opstomen</h1>
            <h1>Ik ben met koffiepauze</h1>
            <h1>deze website heeft nog een espresso of twee nodig</h1>
          </Slider>
        </div>
        <div className="comingsoon__text--contact">
          <p>
            Wil je alvast contact opnemen? Dat kan! Mail me via{" "}
            <a href="mailto:hoi@koffievankai.nl">hoi@koffievankai.nl</a> of bel
            me op <a href="tel:0623999074">06 2399 9074</a>
          </p>
        </div>
      </div>
      <div className="comingsoon__image" />
    </div>
  );
};

export default ComingSoonView;
